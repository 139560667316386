/**
 * Notification Bar: (AKA Page Banner)
 * This is a dismissible banner at the top of the landing page for
 * mc-users to notify Landing Page visitors of information about data
 * collection or other page info.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "mojo/utils/styles", "mojo/user", "mojo/lib/flags", "velocity/velocity", "velocity/velocity.ui"], function (declare, lang, domConstruct, domStyle, on, query, Stateful, _TemplatedMixin, _WidgetBase, styles, user, flags, Velocity) {
  /**
   * Page Banner
   * page banner model will have styles and content, and later may have settings.
   */
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<div class=\"mcnTemplatePageBanner\"><div style=\"position:relative\"><div class=\"mcnPageBannerContainer\"><div class=\"mcnPageBannerContentContainer\" data-dojo-attach-point=\"contentContainer\"></div></div><div class=\"mcnCloseButton\" data-dojo-attach-point=\"closeBtn\" role=\"button\"><span class=\"sr-only\">close</span></div></div></div>",
    localStoragePrefix: "mc-lp-notification_" + window.location.pathname,
    closeBtnImage: "<img width=\"24\" height=\"24\" alt=\"close\" src=\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAAEVQTFRFAAAA////////////////////////////////////////////////////////AAAACwsLlpaWv7+/wMDAwcHB5+fn////js+8jgAAAA90Uk5TABkaT2lvcJi1ttny8/v+KMQKuwAAAJdJREFUKM91UuEawiAIRGdp6pZzi/d/1HDo11bd/RAFTk6QSGFdiDnH4CydYfzMHbM3H/+U+IQ0Df994QuWW8//8kvk4JjEP0itjtf962K86FQ9a6nN1LKqNktOE8uzbMybGOU4eii3imvfZal6DhR7vZastAOR8lAi6UIap4wD8KqAikO58IGwJbiJsO14UHi0+DP8+z5v3a8i3EEOD1IAAAAASUVORK5CYII=\" />",
    constructor: function (params) {
      if (params && params.isLive) {
        this.model = new Stateful(params.settings);
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      var lsObj = window.localStorage.getItem(this.localStoragePrefix);
      var shouldUserViewNotification = true;

      // detect if the page is loading in a preview frame or not
      var userObj = user.getBootstrappedInstance();
      var isOutsideOfAppContext = !userObj || !userObj.userId;

      // get settings and insert
      this.model.watch("enabled", this.updateEnabled.bind(this));
      this.model.watch("styles", this.updateStyles.bind(this));
      this.model.watch("content", this.updateContent.bind(this));

      // add content to domNode
      this.updateContent();

      // add styles to domNode
      // this.model.styles.props
      // this.model.styles.selector
      this.updateStyles();
      if (this.isLive && lsObj && isOutsideOfAppContext) {
        lsObj = JSON.parse(lsObj);
        if (!this.isPreview && lsObj.hasOwnProperty("hasClosed") && lsObj.hasClosed) {
          shouldUserViewNotification = false;
        }
      }
      domConstruct.place(this.closeBtnImage, this.closeBtn);
      if (!this.isLive) {
        // setup click event for Preview
        // listen for click on the entire widget
        on(this.domNode, "click", function () {
          this.onClickPreview();
        }.bind(this));

        // add events to closeBtn
        on(this.closeBtn, "click", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });
      } else {
        // add events to closeBtn
        on(this.closeBtn, "click", function () {
          this.onClickClose();
        }.bind(this));
      }
      // hide if enabled is false
      if (!this.model.get("enabled") || !shouldUserViewNotification) {
        domStyle.set(this.domNode, "display", "none");
        this._hide();
        return;
      }
      domStyle.set(this.domNode, "display", "none");
      this._show();
    },
    updateEnabled: function (attr, oldValue, newValue) {
      if (oldValue !== newValue) {
        if (newValue === true) {
          this._show();
        } else {
          this._hide();
        }
      }
    },
    updateStyles: function () {
      styles.reset(this.domNode, this.model.styles);
      styles.inline(this.domNode, this.model.styles);
    },
    updateContent: function () {
      var contentNode = domConstruct.toDom(this.model.get("content"));
      domConstruct.place(contentNode, this.contentContainer, "only");
    },
    onClickClose: function () {
      this._hide(true);
    },
    _hide: function (destroy) {
      // animted out
      var transitionString = "transition.bounceDownOut";
      Velocity(this.domNode, transitionString, {
        // "easing": "easeOutQuart",
        "duration": 700,
        "complete": lang.hitch(this, function () {
          if (destroy) {
            if (this.isLive) {
              window.localStorage.setItem(this.localStoragePrefix, JSON.stringify({
                hasClosed: true
              }));
            }
            this.destroy();
          }
        }),
        display: "none"
      });
    },
    _show: function () {
      // animted in
      Velocity(this.domNode, "transition.slideDownIn", {
        "easing": "easeInQuart",
        "duration": 400,
        "delay": 400,
        display: "inherit"
      });
    },
    destroy: function () {
      domConstruct.destroy(this.domNode);
    }
  });
});