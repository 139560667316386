/**
 * Opt Out Link
 * This is a widget that is responsible for rendering an
 * opt out link for landing pages.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/page/request"], function (declare, lang, dom, query, on, request) {
  return declare([], {
    constructor: function (params) {
      var elements = document.querySelectorAll("a[data-tracking-opt-out],a[data-page-banner-opt-out]");
      if (elements.length > 0) {
        this._setupLinks(elements, params);
      }
    },
    _setupLinks: function (elements, params) {
      request.get(params.checkStatusUrl).then(lang.hitch(this, function (response) {
        if (response.error === undefined && response.enabled === true) {
          Array.prototype.forEach.call(elements, lang.hitch(this, function (element) {
            this._replaceWithDefaultText(element);
          }));
        } else if (params.isPreview !== true) {
          Array.prototype.forEach.call(elements, lang.hitch(this, function (element) {
            this._setupOnClickHandler(element, params);
          }));
        }
      }));
    },
    _setupOnClickHandler: function (element, params) {
      // listen for click on the element
      on(element, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._handleClick(params);
      }));
    },
    _handleClick: function (params) {
      request.get(params.enableUrl).then(lang.hitch(this, function (response) {
        if (response !== "error" && response.success === true) {
          window.location.reload();
        }
      }));
    },
    _replaceWithDefaultText: function (element) {
      var newElement = document.createElement("p");
      newElement.innerHTML = "You’ve opted out of tracking";
      element.parentNode.replaceChild(newElement, element);
    }
  });
});