define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "./templates/privacy-badge.html", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags"], function (declare, lang, domStyle, domAttr, on, query, Stateful, _TemplatedMixin, _WidgetBase, tpl, I18nTranslation, context, flags) {
  var PRIVACY_LINKS = {
    "report_abuse": "https://mailchimp.com/contact/abuse/",
    "monkeyrewards": "" // set in the postCreate() method
  };
  var PrivacyBadge = {
    templateString: null,
    // Set in constructor

    isLivePage: false,
    /* Applies to this.domNode */
    baseContainerStyles: {
      "position": "absolute",
      "bottom": "0",
      "right": "0",
      "left": "0",
      "display": "flex",
      "justify-content": "center"
    },
    /* Applies to div.mcPrivacyBadge */
    baseBadgeStyles: {
      "font-size": "14px",
      "font-family": "Helvetica, Arial, Verdana, sans-serif",
      "text-align": "center",
      "cursor": "pointer",
      "width": "100%",
      "padding": "12px 24px"
    },
    /* Applies to div.mcPrivacyBadge > a#monkeyrewards */
    monkeyrewardsLinkStyles: {
      "width": "163px",
      "height": "40px",
      "display": "flex",
      "font-size": "10px",
      "align-items": "center",
      "margin-left": "12px",
      "text-decoration": "none"
    },
    themeStyles: {
      light: {
        badgeStyles: {
          "background-color": "rgba(255, 255, 255, 0.6)",
          "opacity": "0.8",
          "color": "#241c15",
          // @peppercorn
          "border-top": "none"
        },
        monkeyrewardsImg: "https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg",
        linkColor: "black"
      },
      dark: {
        badgeStyles: {
          "background-color": "#242424",
          "opacity": "1",
          "color": "#FFFFFF",
          // @coconut
          "border-top": "1px solid #FFFFFF",
          "box-shadow": "none"
        },
        monkeyrewardsImg: "https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-light.svg",
        linkColor: "#FFFFFF" // @coconut
      }
    },
    reportAbuse: "Report Abuse",
    constructor: function (params) {
      if (params && params.isLive) {
        // If isLive is false, model (Stateful) should be directly passed
        // through params.model
        this.model = new Stateful(params.settings);
      }
      this.templateString = tpl;
      this.inherited(arguments);
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.reportAbuse = I18nTranslation.translate("nea_report_abuse");
      }
    },
    postCreate: function () {
      this.rewardsLink = this.domNode.querySelector(".monkeyrewards");
      this.logoImage = this.rewardsLink.querySelector(".logo");
      PRIVACY_LINKS.monkeyrewards = this.model.get("isWebsitePage") ? "https://mailchimp.com/referral/?utm_source=freemium_website&utm_medium=website&utm_campaign=referral_marketing" : "https://mailchimp.com/referral/?utm_source=freemium_landingpage&utm_medium=landingpage&utm_campaign=referral_marketing";

      // set styles from theme
      this._setStylesFromTheme();

      // set style for body to add some padding
      var nodes = document.querySelectorAll(".templateSection");
      if (nodes && nodes.length > 0) {
        var lastTemplateSection = nodes[nodes.length - 1];
        var lastTemplateSectionPadding = domStyle.get(lastTemplateSection, "padding-bottom");
        domStyle.set(lastTemplateSection, "padding-bottom", lastTemplateSectionPadding + 140 + "px");
      }

      // hide if enabled is false
      if (!this.model.get("enabled")) {
        this._hide();
      }
      if (this.uniqueId) {
        domAttr.set(this.rewardsLink, "href", PRIVACY_LINKS.monkeyrewards.replace(/%ACCOUNT_ID%/, this.uniqueId));
      }
      if (!this.isLive) {
        // add event handlers for editing mode
        this.own(
        // listen for click on the entire widget
        on(this.domNode, "click", lang.hitch(this, function () {
          this.onClick(this);
        })));
      } else {
        domAttr.set(this.domNode.querySelector(".report-abuse"), "href", PRIVACY_LINKS.report_abuse);
      }
      this.model.watch("theme", lang.hitch(this, "updateTheme"));
      this.model.watch("enabled", lang.hitch(this, "updateEnabled"));
    },
    updateTheme: function (attr, oldValue, newValue) {
      if (oldValue !== newValue) {
        this._setStylesFromTheme();
      }
    },
    updateEnabled: function (attr, oldValue, newValue) {
      if (oldValue !== newValue) {
        if (newValue === true) {
          this._show();
        } else {
          this._hide();
        }
      }
    },
    _setStylesFromTheme: function () {
      var themeName = this.model.get("theme");
      var theme = this.themeStyles[themeName];
      this.logoImage.src = theme.monkeyrewardsImg;
      domStyle.set(this.domNode, this.baseContainerStyles);
      domStyle.set(this.domNode.querySelector(".mcPrivacyBadge"), lang.mixin(this.baseBadgeStyles, theme.badgeStyles));
      domStyle.set(this.rewardsLink, this.monkeyrewardsLinkStyles);
      query("a.privacyLink", this.domNode).forEach(function (el) {
        domStyle.set(el, "color", theme.linkColor);
      });
      // ensures link color in editor preview is consistent with page preview
      query("a.monkeyrewards", this.domNode).forEach(function (el) {
        domStyle.set(el, "color", theme.linkColor);
      });
    },
    _hide: function () {
      domStyle.set(this.domNode, "display", "none");
    },
    _show: function () {
      domStyle.set(this.domNode, "display", "flex");
    }
  };
  var module = declare([_WidgetBase, _TemplatedMixin], PrivacyBadge);

  // Allow static styles to be accessed without instantiation
  module.themeStyles = PrivacyBadge.themeStyles;
  module.baseBadgeStyles = PrivacyBadge.baseBadgeStyles;
  module.baseContainerStyles = PrivacyBadge.baseContainerStyles;
  return module;
});