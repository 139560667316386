/**
 * @module mojo/internationalization/logger
 *
 * Normalization utility around emitting events to elastic search. Worth noting this implementation has a dependency
 * on the track/log endpoint which can be throttled and assumes active customer session.
 *
 * @see app/controllers/TrackController.php
 */
define(["mojo/lib/logger"], function (mLogger) {
  var ELASTIC_SEARCH_INDEX_NAME = "intlx-dojo";
  return {
    // Useful for debugging purposes.
    shouldEmitToBrowserConsole: false,
    /**
     * Simple helper function for emitting a log
     *
     * @param {string} message Message to send
     * @param {Object} data Extra information to log
     */
    log: function (message, data) {
      // Handle the situation someone misses a func argument
      data = data || {};

      // Decorate data with the path.
      data.pathname = window.top.location.pathname;
      if (this.shouldEmitToBrowserConsole) {
        // eslint-disable-next-line no-console
        console.debug(ELASTIC_SEARCH_INDEX_NAME + " event fired.", {
          message: message,
          data: data
        });
      }

      // Emit logging event. Note: This function swallows error responses from the /track/log endpoint.
      mLogger.info(ELASTIC_SEARCH_INDEX_NAME, message, data);
    }
  };
});