/**
 * General logging helper library
 *
 * This module provides a bag of functions related to sending logging information to the backend.
 */
define(["dojo/request", "mojo/url", "vendor/lodash-amd/identity", "mojo/context"], function (request, mUrl, identityFunction, context) {
  var logger = {
    googleAnalytics: {
      /**
       * Send a GA tracking event
       * @param {string} category like a logger channel name
       * @param {string} action is how the user interacted with the category
       * @param {string} label is the data
       * @param {Object} fieldsObject An object, useful for setting an event as a nonInteraction
       */
      trackEvent: function (category, action, label, fieldsObject) {
        window.ga("send", "event", category, action, label, fieldsObject);
      }
    },
    /**
     * @deprecated Please migrate to logger.info
     */
    pythia: {
      logEvent: function (eventName, eventData) {
        if (typeof eventName != 'string') {
          throw new Error("You must provide an event name");
        }
        if (typeof eventData === 'undefined') {
          eventData = {};
        }
        eventData.event_name = eventName;
        logger.logData(eventData, 'pythia');
      }
    },
    marketing: {
      /**
       *  Use to log generic, unstructured data to the mailchimp-marketing-events ElasticSearch type
       */
      logData: function (data) {
        logger.logData(data, 'marketing');
      }
    },
    /**
     * Send a logging event
     * @param {string} channelName Index to track into
     * @param {string} message Message to send
     * @param {Object} data Extra information to log
     */
    info: function (channelName, message, data) {
      if (typeof channelName !== "string") {
        throw new Error("channelName must be provided");
      } else if (typeof message !== "string") {
        throw new Error("message must be provided");
      }
      data = data || {};
      request.post(mUrl.toUrl("track/log"), {
        "data": {
          "channelName": channelName,
          "message": message,
          "data": JSON.stringify(data)
        }
      }).then(identityFunction, function () {
        /* swallow errors hitting /track/log because its load shedding and that's just fine */
      });
    },
    /**
     * Use to log generic, unstructured data to any ElasticSearch type
     * @param {Object} logData Object of data to attach
     * @param {string} logType Which log to point to
     */
    logData: function (logData, logType) {
      if (typeof logData === 'undefined') {
        logData = {};
      }
      if (typeof logType != 'string') {
        throw new Error("You must provide a log type");
      }
      var postParams = {
        data: JSON.stringify(logData),
        type: logType
      };
      request.post(rootUrl + 'track', {
        data: postParams
      });
    },
    /**
     *  Standardize a path for eaiser data analysis
     *  /something/ -> /something
     *  /something-post -> /something
     */
    stdPath: function (str) {
      str = str.replace(/\/$/, '');
      str = str.replace(/-post$/, '');
      return str;
    },
    /**
    * This is used to log Monetization and Revenue Tracking Adapter (MARTA) metrics
    */
    marta: {
      /**
       * Sends MARTA eventId and sessionID to the backend where it grabs corresponding user data
       * @param {string} eventId like a logger channel name
       */
      trackEvent: function (eventId) {
        var browserSessionID = null;
        try {
          browserSessionID = sessionStorage.getItem("sessionID");
          browserSessionID = JSON.parse(browserSessionID);
        } catch (e) {
          // do nothing.
        }
        request.get(mUrl.toUrl("/marta/track"), {
          "handleAs": "json",
          "query": {
            "eventId": eventId,
            "browserSessionId": browserSessionID
          }
        }).then(function (data) {
          if (!data.isMartaIdValid) {
            if (context.avestaEnvironment && context.avestaEnvironment === "dev") {
              console.error("\"" + eventId + "\"" + "is an invalid MARTA ID. " + "You must provide a valid one to the logger.marta.trackEvent() function that matches up with a valid ID in the MARTA manifest. " + "This MANIFEST array can be found in /MC/Analytics/Monetization/MartaEvent.php.");
            }
          }
        });
      }
    },
    /**
     * This is used to send an event to the Events Pipeline V2's PHP client.
     * Please refer to the Events Pipeline V2 Developer Guide (JS Mojo tab) on how to get started:
     * https://confluence.mailchimp.com/pages/viewpage.action?spaceKey=DSVC&title=Events+Pipeline+V2+Developer+Guide
     * Example usage:
     *  var customProperties = { campaign_id: 5, is_pro: true, location: "website banner"};
     *  var optionalData = {
     *      customLoginTraits: { login_trait_a: "cool_trait", login_trait_b: 2 } OR null,
     *      customAccountTraits: { account_trait_b: "nice_trait", account_trait_b: false } or null,
     *  }
     * logger.eventsPipeline.publish("test:eventV2", customProperties, optionalData)
     */
    eventsPipeline: {
      /**
       * @param {string} eventName name of event
       * @param {Object} customProperties - event-specific properties containing information about the event itself
       * @param {Object|null} optionalData a parent object containing:
       * - customLoginTraits {Object|null} - login-related data that specify the “who” behind the event
       * - customAccountTraits {Object|null} - customer account-related data behind the event that affect all users associated with the account
       */
      publish: function (eventName, customProperties, optionalData) {
        if (!eventName || typeof eventName !== "string") {
          console.error("Event name must be provided.");
        }
        if (!customProperties || Object.keys(customProperties).length < 1) {
          console.error("Event must have at least 1 custom property.");
        }
        var customLoginTraits = optionalData && optionalData.customLoginTraits ? JSON.stringify(optionalData.customLoginTraits) : null;
        var customAccountTraits = optionalData && optionalData.customAccountTraits ? JSON.stringify(optionalData.customAccountTraits) : null;
        request.post(mUrl.toUrl("events-pipeline-dojo/publish"), {
          "handleAs": "json",
          "data": {
            "event": eventName,
            "customProperties": JSON.stringify(customProperties),
            "customLoginTraits": customLoginTraits,
            "customAccountTraits": customAccountTraits
          }
        });
      }
    }
  };
  return logger;
});