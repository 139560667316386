/**
 * Page Context
 * @module mojo/context.js
 *
 * Holds any information that might change depending in the environment Mailchimp runs on.
 * DO NOT ADD ANYTHING that's user specific or requires a user. Do that in mojo/user, otherwise (╯°□°)╯︵ ┻━┻
 *
 * see _js_settings.html for how these values are overridden
 */
define([], function () {
  // [FP-682]: We need to hydrate context.flag first thing so that webpack
  // will not error out when eagerly load the flags.
  var flagData;
  var flagDataNode = document.getElementById("js-legacy-flag-data");
  if (flagDataNode) {
    try {
      flagData = JSON.parse(flagDataNode.textContent);
      flagDataNode.textContent = "";
    } catch (e) {
      console.error(e);
    }
  }
  var context = {
    rootUrl: "/",
    proxyBaseUrl: null,
    listManageDomain: null,
    pusherKey: null,
    cdnImagesDomain: null,
    galleryDomain: null,
    avestaEnvironment: null,
    imageEditorUrl: null,
    imageEditorKey: null,
    solvvyUrl: null,
    enabledFlags: flagData,
    bridgeBundles: null,
    googleOAuth2: {},
    /**
     * Check whether a particular flag is enabled. The name is often a reference
     * to a "constant" as defined in mojo/lib/flags.js
     *
     * @param {string} flagName - Name of flag
     *
     * @returns {boolean} - True if the backend passed the flag name into enabledFlags.
     */
    flagIsOn: function (flagName) {
      if (!flagDataNode) {
        throw new Error("No flag node found on this page");
      }
      if (!this.enabledFlags) {
        // There are situations in which this function could be called without proper flag context.
        // The contents of `enabledFlags` and other keys within this file are hydrated in most views via
        // injection of `views/default/global/_js_settings.html`. If using Avesta views, the parent template
        // might be taking care of this already.
        //
        // This error will most often appear when performing a flag check on widgets that are part of an iframe
        // that is rendered separately from our standard views or the flag check is performed before
        // `enabledFlags` has been filled.
        throw new Error("No flag context found in this frame.");
      }
      if (!Array.isArray(this.enabledFlags)) {
        throw new TypeError("this.enabledFlags is expected to be an array");
      }
      if (typeof flagName !== "string") {
        if (this.avestaEnvironment === "dev") {
          throw new TypeError("flagName is not a string. Please make sure your flag is declared in web/js/mojo/lib/flags.js.");
        }
        console.error("flagName is expected to be a string");
        return false;
      }
      var isFlagOn = this.enabledFlags.indexOf(flagName) !== -1;
      return isFlagOn;
    }
  };
  return context;
});