define([], function () {
  return '\
        <div>\
            <div class="mcPrivacyBadge"> \
                <div class="contentContainer mcPrivacyBadgeContent" style="display: flex; align-items: center; justify-content: space-between; margin: auto;"> \
                    <div class="reportAbuseWrapper"> \
                        <a class="privacyLink report-abuse" target="_blank">${reportAbuse}</a> \
                    </div> \
                    <a class="monkeyrewards" target="_blank"> \
                        <img src="" style="width: 100px;" class="logo" /> \
                    </a> \
                </div> \
            </div> \
        </div>\
    ';
});