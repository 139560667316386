define(["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useDateLocalizer = useDateLocalizer;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
  function getDefaultExportFromCjs(x) {
    return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
  }
  var dayjs_min = {
    exports: {}
  };
  (function (module, exports) {
    !function (t, e) {
      module.exports = e();
    }(commonjsGlobal, function () {
      var t = 1e3,
        e = 6e4,
        n = 36e5,
        r = "millisecond",
        i = "second",
        s = "minute",
        u = "hour",
        a = "day",
        o = "week",
        c = "month",
        f = "quarter",
        h = "year",
        d = "date",
        l = "Invalid Date",
        $ = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/,
        y = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g,
        M = {
          name: "en",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          ordinal: function ordinal(t2) {
            var e2 = ["th", "st", "nd", "rd"],
              n2 = t2 % 100;
            return "[" + t2 + (e2[(n2 - 20) % 10] || e2[n2] || e2[0]) + "]";
          }
        },
        m = function m(t2, e2, n2) {
          var r2 = String(t2);
          return !r2 || r2.length >= e2 ? t2 : "" + Array(e2 + 1 - r2.length).join(n2) + t2;
        },
        v = {
          s: m,
          z: function z(t2) {
            var e2 = -t2.utcOffset(),
              n2 = Math.abs(e2),
              r2 = Math.floor(n2 / 60),
              i2 = n2 % 60;
            return (e2 <= 0 ? "+" : "-") + m(r2, 2, "0") + ":" + m(i2, 2, "0");
          },
          m: function t2(e2, n2) {
            if (e2.date() < n2.date()) return -t2(n2, e2);
            var r2 = 12 * (n2.year() - e2.year()) + (n2.month() - e2.month()),
              i2 = e2.clone().add(r2, c),
              s2 = n2 - i2 < 0,
              u2 = e2.clone().add(r2 + (s2 ? -1 : 1), c);
            return +(-(r2 + (n2 - i2) / (s2 ? i2 - u2 : u2 - i2)) || 0);
          },
          a: function a(t2) {
            return t2 < 0 ? Math.ceil(t2) || 0 : Math.floor(t2);
          },
          p: function p(t2) {
            return {
              M: c,
              y: h,
              w: o,
              d: a,
              D: d,
              h: u,
              m: s,
              s: i,
              ms: r,
              Q: f
            }[t2] || String(t2 || "").toLowerCase().replace(/s$/, "");
          },
          u: function u(t2) {
            return void 0 === t2;
          }
        },
        g = "en",
        D = {};
      D[g] = M;
      var p = "$isDayjsObject",
        S = function S(t2) {
          return t2 instanceof _ || !(!t2 || !t2[p]);
        },
        w = function t2(e2, n2, r2) {
          var i2;
          if (!e2) return g;
          if ("string" == typeof e2) {
            var s2 = e2.toLowerCase();
            D[s2] && (i2 = s2), n2 && (D[s2] = n2, i2 = s2);
            var u2 = e2.split("-");
            if (!i2 && u2.length > 1) return t2(u2[0]);
          } else {
            var a2 = e2.name;
            D[a2] = e2, i2 = a2;
          }
          return !r2 && i2 && (g = i2), i2 || !r2 && g;
        },
        O = function O(t2, e2) {
          if (S(t2)) return t2.clone();
          var n2 = "object" == _typeof(e2) ? e2 : {};
          return n2.date = t2, n2.args = arguments, new _(n2);
        },
        b = v;
      b.l = w, b.i = S, b.w = function (t2, e2) {
        return O(t2, {
          locale: e2.$L,
          utc: e2.$u,
          x: e2.$x,
          $offset: e2.$offset
        });
      };
      var _ = function () {
          function M2(t2) {
            this.$L = w(t2.locale, null, true), this.parse(t2), this.$x = this.$x || t2.x || {}, this[p] = true;
          }
          var m2 = M2.prototype;
          return m2.parse = function (t2) {
            this.$d = function (t3) {
              var e2 = t3.date,
                n2 = t3.utc;
              if (null === e2) return /* @__PURE__ */new Date(NaN);
              if (b.u(e2)) return /* @__PURE__ */new Date();
              if (e2 instanceof Date) return new Date(e2);
              if ("string" == typeof e2 && !/Z$/i.test(e2)) {
                var r2 = e2.match($);
                if (r2) {
                  var i2 = r2[2] - 1 || 0,
                    s2 = (r2[7] || "0").substring(0, 3);
                  return n2 ? new Date(Date.UTC(r2[1], i2, r2[3] || 1, r2[4] || 0, r2[5] || 0, r2[6] || 0, s2)) : new Date(r2[1], i2, r2[3] || 1, r2[4] || 0, r2[5] || 0, r2[6] || 0, s2);
                }
              }
              return new Date(e2);
            }(t2), this.init();
          }, m2.init = function () {
            var t2 = this.$d;
            this.$y = t2.getFullYear(), this.$M = t2.getMonth(), this.$D = t2.getDate(), this.$W = t2.getDay(), this.$H = t2.getHours(), this.$m = t2.getMinutes(), this.$s = t2.getSeconds(), this.$ms = t2.getMilliseconds();
          }, m2.$utils = function () {
            return b;
          }, m2.isValid = function () {
            return !(this.$d.toString() === l);
          }, m2.isSame = function (t2, e2) {
            var n2 = O(t2);
            return this.startOf(e2) <= n2 && n2 <= this.endOf(e2);
          }, m2.isAfter = function (t2, e2) {
            return O(t2) < this.startOf(e2);
          }, m2.isBefore = function (t2, e2) {
            return this.endOf(e2) < O(t2);
          }, m2.$g = function (t2, e2, n2) {
            return b.u(t2) ? this[e2] : this.set(n2, t2);
          }, m2.unix = function () {
            return Math.floor(this.valueOf() / 1e3);
          }, m2.valueOf = function () {
            return this.$d.getTime();
          }, m2.startOf = function (t2, e2) {
            var n2 = this,
              r2 = !!b.u(e2) || e2,
              f2 = b.p(t2),
              l2 = function l2(t3, e3) {
                var i2 = b.w(n2.$u ? Date.UTC(n2.$y, e3, t3) : new Date(n2.$y, e3, t3), n2);
                return r2 ? i2 : i2.endOf(a);
              },
              $2 = function $2(t3, e3) {
                return b.w(n2.toDate()[t3].apply(n2.toDate("s"), (r2 ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(e3)), n2);
              },
              y2 = this.$W,
              M3 = this.$M,
              m3 = this.$D,
              v2 = "set" + (this.$u ? "UTC" : "");
            switch (f2) {
              case h:
                return r2 ? l2(1, 0) : l2(31, 11);
              case c:
                return r2 ? l2(1, M3) : l2(0, M3 + 1);
              case o:
                var g2 = this.$locale().weekStart || 0,
                  D2 = (y2 < g2 ? y2 + 7 : y2) - g2;
                return l2(r2 ? m3 - D2 : m3 + (6 - D2), M3);
              case a:
              case d:
                return $2(v2 + "Hours", 0);
              case u:
                return $2(v2 + "Minutes", 1);
              case s:
                return $2(v2 + "Seconds", 2);
              case i:
                return $2(v2 + "Milliseconds", 3);
              default:
                return this.clone();
            }
          }, m2.endOf = function (t2) {
            return this.startOf(t2, false);
          }, m2.$set = function (t2, e2) {
            var n2,
              o2 = b.p(t2),
              f2 = "set" + (this.$u ? "UTC" : ""),
              l2 = (n2 = {}, n2[a] = f2 + "Date", n2[d] = f2 + "Date", n2[c] = f2 + "Month", n2[h] = f2 + "FullYear", n2[u] = f2 + "Hours", n2[s] = f2 + "Minutes", n2[i] = f2 + "Seconds", n2[r] = f2 + "Milliseconds", n2)[o2],
              $2 = o2 === a ? this.$D + (e2 - this.$W) : e2;
            if (o2 === c || o2 === h) {
              var y2 = this.clone().set(d, 1);
              y2.$d[l2]($2), y2.init(), this.$d = y2.set(d, Math.min(this.$D, y2.daysInMonth())).$d;
            } else l2 && this.$d[l2]($2);
            return this.init(), this;
          }, m2.set = function (t2, e2) {
            return this.clone().$set(t2, e2);
          }, m2.get = function (t2) {
            return this[b.p(t2)]();
          }, m2.add = function (r2, f2) {
            var d2,
              l2 = this;
            r2 = Number(r2);
            var $2 = b.p(f2),
              y2 = function y2(t2) {
                var e2 = O(l2);
                return b.w(e2.date(e2.date() + Math.round(t2 * r2)), l2);
              };
            if ($2 === c) return this.set(c, this.$M + r2);
            if ($2 === h) return this.set(h, this.$y + r2);
            if ($2 === a) return y2(1);
            if ($2 === o) return y2(7);
            var M3 = (d2 = {}, d2[s] = e, d2[u] = n, d2[i] = t, d2)[$2] || 1,
              m3 = this.$d.getTime() + r2 * M3;
            return b.w(m3, this);
          }, m2.subtract = function (t2, e2) {
            return this.add(-1 * t2, e2);
          }, m2.format = function (t2) {
            var e2 = this,
              n2 = this.$locale();
            if (!this.isValid()) return n2.invalidDate || l;
            var r2 = t2 || "YYYY-MM-DDTHH:mm:ssZ",
              i2 = b.z(this),
              s2 = this.$H,
              u2 = this.$m,
              a2 = this.$M,
              o2 = n2.weekdays,
              c2 = n2.months,
              f2 = n2.meridiem,
              h2 = function h2(t3, n3, i3, s3) {
                return t3 && (t3[n3] || t3(e2, r2)) || i3[n3].slice(0, s3);
              },
              d2 = function d2(t3) {
                return b.s(s2 % 12 || 12, t3, "0");
              },
              $2 = f2 || function (t3, e3, n3) {
                var r3 = t3 < 12 ? "AM" : "PM";
                return n3 ? r3.toLowerCase() : r3;
              };
            return r2.replace(y, function (t3, r3) {
              return r3 || function (t4) {
                switch (t4) {
                  case "YY":
                    return String(e2.$y).slice(-2);
                  case "YYYY":
                    return b.s(e2.$y, 4, "0");
                  case "M":
                    return a2 + 1;
                  case "MM":
                    return b.s(a2 + 1, 2, "0");
                  case "MMM":
                    return h2(n2.monthsShort, a2, c2, 3);
                  case "MMMM":
                    return h2(c2, a2);
                  case "D":
                    return e2.$D;
                  case "DD":
                    return b.s(e2.$D, 2, "0");
                  case "d":
                    return String(e2.$W);
                  case "dd":
                    return h2(n2.weekdaysMin, e2.$W, o2, 2);
                  case "ddd":
                    return h2(n2.weekdaysShort, e2.$W, o2, 3);
                  case "dddd":
                    return o2[e2.$W];
                  case "H":
                    return String(s2);
                  case "HH":
                    return b.s(s2, 2, "0");
                  case "h":
                    return d2(1);
                  case "hh":
                    return d2(2);
                  case "a":
                    return $2(s2, u2, true);
                  case "A":
                    return $2(s2, u2, false);
                  case "m":
                    return String(u2);
                  case "mm":
                    return b.s(u2, 2, "0");
                  case "s":
                    return String(e2.$s);
                  case "ss":
                    return b.s(e2.$s, 2, "0");
                  case "SSS":
                    return b.s(e2.$ms, 3, "0");
                  case "Z":
                    return i2;
                }
                return null;
              }(t3) || i2.replace(":", "");
            });
          }, m2.utcOffset = function () {
            return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
          }, m2.diff = function (r2, d2, l2) {
            var $2,
              y2 = this,
              M3 = b.p(d2),
              m3 = O(r2),
              v2 = (m3.utcOffset() - this.utcOffset()) * e,
              g2 = this - m3,
              D2 = function D2() {
                return b.m(y2, m3);
              };
            switch (M3) {
              case h:
                $2 = D2() / 12;
                break;
              case c:
                $2 = D2();
                break;
              case f:
                $2 = D2() / 3;
                break;
              case o:
                $2 = (g2 - v2) / 6048e5;
                break;
              case a:
                $2 = (g2 - v2) / 864e5;
                break;
              case u:
                $2 = g2 / n;
                break;
              case s:
                $2 = g2 / e;
                break;
              case i:
                $2 = g2 / t;
                break;
              default:
                $2 = g2;
            }
            return l2 ? $2 : b.a($2);
          }, m2.daysInMonth = function () {
            return this.endOf(c).$D;
          }, m2.$locale = function () {
            return D[this.$L];
          }, m2.locale = function (t2, e2) {
            if (!t2) return this.$L;
            var n2 = this.clone(),
              r2 = w(t2, e2, true);
            return r2 && (n2.$L = r2), n2;
          }, m2.clone = function () {
            return b.w(this.$d, this);
          }, m2.toDate = function () {
            return new Date(this.valueOf());
          }, m2.toJSON = function () {
            return this.isValid() ? this.toISOString() : null;
          }, m2.toISOString = function () {
            return this.$d.toISOString();
          }, m2.toString = function () {
            return this.$d.toUTCString();
          }, M2;
        }(),
        k = _.prototype;
      return O.prototype = k, [["$ms", r], ["$s", i], ["$m", s], ["$H", u], ["$W", a], ["$M", c], ["$y", h], ["$D", d]].forEach(function (t2) {
        k[t2[1]] = function (e2) {
          return this.$g(e2, t2[0], t2[1]);
        };
      }), O.extend = function (t2, e2) {
        return t2.$i || (t2(e2, _, O), t2.$i = true), O;
      }, O.locale = w, O.isDayjs = S, O.unix = function (t2) {
        return O(1e3 * t2);
      }, O.en = D[g], O.Ls = D, O.p = {}, O;
    });
  })(dayjs_min);
  var dayjs_minExports = dayjs_min.exports;
  var dayjs = /* @__PURE__ */getDefaultExportFromCjs(dayjs_minExports);
  var af = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var n = a(e),
        t = {
          name: "af",
          weekdays: "Sondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrydag_Saterdag".split("_"),
          months: "Januarie_Februarie_Maart_April_Mei_Junie_Julie_Augustus_September_Oktober_November_Desember".split("_"),
          weekStart: 1,
          weekdaysShort: "Son_Maa_Din_Woe_Don_Vry_Sat".split("_"),
          monthsShort: "Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Des".split("_"),
          weekdaysMin: "So_Ma_Di_Wo_Do_Vr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "oor %s",
            past: "%s gelede",
            s: "'n paar sekondes",
            m: "'n minuut",
            mm: "%d minute",
            h: "'n uur",
            hh: "%d ure",
            d: "'n dag",
            dd: "%d dae",
            M: "'n maand",
            MM: "%d maande",
            y: "'n jaar",
            yy: "%d jaar"
          }
        };
      return n.default.locale(t, null, true), t;
    });
  })(af);
  var ar = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, t) {
      module.exports = t(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function t(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var n = t(e),
        r = "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split("_"),
        d = {
          1: "١",
          2: "٢",
          3: "٣",
          4: "٤",
          5: "٥",
          6: "٦",
          7: "٧",
          8: "٨",
          9: "٩",
          0: "٠"
        },
        _ = {
          "١": "1",
          "٢": "2",
          "٣": "3",
          "٤": "4",
          "٥": "5",
          "٦": "6",
          "٧": "7",
          "٨": "8",
          "٩": "9",
          "٠": "0"
        },
        o = {
          name: "ar",
          weekdays: "الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت".split("_"),
          weekdaysShort: "أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت".split("_"),
          weekdaysMin: "ح_ن_ث_ر_خ_ج_س".split("_"),
          months: r,
          monthsShort: r,
          weekStart: 6,
          meridiem: function meridiem(e2) {
            return e2 > 12 ? "م" : "ص";
          },
          relativeTime: {
            future: "بعد %s",
            past: "منذ %s",
            s: "ثانية واحدة",
            m: "دقيقة واحدة",
            mm: "%d دقائق",
            h: "ساعة واحدة",
            hh: "%d ساعات",
            d: "يوم واحد",
            dd: "%d أيام",
            M: "شهر واحد",
            MM: "%d أشهر",
            y: "عام واحد",
            yy: "%d أعوام"
          },
          preparse: function preparse(e2) {
            return e2.replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (e3) {
              return _[e3];
            }).replace(/،/g, ",");
          },
          postformat: function postformat(e2) {
            return e2.replace(/\d/g, function (e3) {
              return d[e3];
            }).replace(/,/g, "،");
          },
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "D/‏M/‏YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          }
        };
      return n.default.locale(o, null, true), o;
    });
  })(ar);
  var arSa = {
    exports: {}
  };
  (function (module, exports) {
    !function (_, e) {
      module.exports = e(dayjs_minExports);
    }(commonjsGlobal, function (_) {
      function e(_2) {
        return _2 && "object" == _typeof(_2) && "default" in _2 ? _2 : {
          default: _2
        };
      }
      var t = e(_),
        d = {
          name: "ar-sa",
          weekdays: "الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت".split("_"),
          months: "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split("_"),
          weekdaysShort: "أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت".split("_"),
          monthsShort: "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split("_"),
          weekdaysMin: "ح_ن_ث_ر_خ_ج_س".split("_"),
          ordinal: function ordinal(_2) {
            return _2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          meridiem: function meridiem(_2) {
            return _2 > 12 ? "م" : "ص";
          },
          relativeTime: {
            future: "في %s",
            past: "منذ %s",
            s: "ثوان",
            m: "دقيقة",
            mm: "%d دقائق",
            h: "ساعة",
            hh: "%d ساعات",
            d: "يوم",
            dd: "%d أيام",
            M: "شهر",
            MM: "%d أشهر",
            y: "سنة",
            yy: "%d سنوات"
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(arSa);
  var bg = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, _) {
      module.exports = _(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function _(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = _(e),
        d = {
          name: "bg",
          weekdays: "неделя_понеделник_вторник_сряда_четвъртък_петък_събота".split("_"),
          weekdaysShort: "нед_пон_вто_сря_чет_пет_съб".split("_"),
          weekdaysMin: "нд_пн_вт_ср_чт_пт_сб".split("_"),
          months: "януари_февруари_март_април_май_юни_юли_август_септември_октомври_ноември_декември".split("_"),
          monthsShort: "янр_фев_мар_апр_май_юни_юли_авг_сеп_окт_ное_дек".split("_"),
          weekStart: 1,
          ordinal: function ordinal(e2) {
            var _2 = e2 % 100;
            if (_2 > 10 && _2 < 20) return e2 + "-ти";
            var t2 = e2 % 10;
            return 1 === t2 ? e2 + "-ви" : 2 === t2 ? e2 + "-ри" : 7 === t2 || 8 === t2 ? e2 + "-ми" : e2 + "-ти";
          },
          formats: {
            LT: "H:mm",
            LTS: "H:mm:ss",
            L: "D.MM.YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY H:mm",
            LLLL: "dddd, D MMMM YYYY H:mm"
          },
          relativeTime: {
            future: "след %s",
            past: "преди %s",
            s: "няколко секунди",
            m: "минута",
            mm: "%d минути",
            h: "час",
            hh: "%d часа",
            d: "ден",
            dd: "%d дена",
            M: "месец",
            MM: "%d месеца",
            y: "година",
            yy: "%d години"
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(bg);
  var ca = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, s) {
      module.exports = s(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function s(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = s(e),
        _ = {
          name: "ca",
          weekdays: "Diumenge_Dilluns_Dimarts_Dimecres_Dijous_Divendres_Dissabte".split("_"),
          weekdaysShort: "Dg._Dl._Dt._Dc._Dj._Dv._Ds.".split("_"),
          weekdaysMin: "Dg_Dl_Dt_Dc_Dj_Dv_Ds".split("_"),
          months: "Gener_Febrer_Març_Abril_Maig_Juny_Juliol_Agost_Setembre_Octubre_Novembre_Desembre".split("_"),
          monthsShort: "Gen._Febr._Març_Abr._Maig_Juny_Jul._Ag._Set._Oct._Nov._Des.".split("_"),
          weekStart: 1,
          formats: {
            LT: "H:mm",
            LTS: "H:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM [de] YYYY",
            LLL: "D MMMM [de] YYYY [a les] H:mm",
            LLLL: "dddd D MMMM [de] YYYY [a les] H:mm",
            ll: "D MMM YYYY",
            lll: "D MMM YYYY, H:mm",
            llll: "ddd D MMM YYYY, H:mm"
          },
          relativeTime: {
            future: "d'aquí %s",
            past: "fa %s",
            s: "uns segons",
            m: "un minut",
            mm: "%d minuts",
            h: "una hora",
            hh: "%d hores",
            d: "un dia",
            dd: "%d dies",
            M: "un mes",
            MM: "%d mesos",
            y: "un any",
            yy: "%d anys"
          },
          ordinal: function ordinal(e2) {
            return "" + e2 + (1 === e2 || 3 === e2 ? "r" : 2 === e2 ? "n" : 4 === e2 ? "t" : "è");
          }
        };
      return t.default.locale(_, null, true), _;
    });
  })(ca);
  var cs = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function n(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = n(e);
      function s(e2) {
        return e2 > 1 && e2 < 5 && 1 != ~~(e2 / 10);
      }
      function r(e2, n2, t2, r2) {
        var d2 = e2 + " ";
        switch (t2) {
          case "s":
            return n2 || r2 ? "pár sekund" : "pár sekundami";
          case "m":
            return n2 ? "minuta" : r2 ? "minutu" : "minutou";
          case "mm":
            return n2 || r2 ? d2 + (s(e2) ? "minuty" : "minut") : d2 + "minutami";
          case "h":
            return n2 ? "hodina" : r2 ? "hodinu" : "hodinou";
          case "hh":
            return n2 || r2 ? d2 + (s(e2) ? "hodiny" : "hodin") : d2 + "hodinami";
          case "d":
            return n2 || r2 ? "den" : "dnem";
          case "dd":
            return n2 || r2 ? d2 + (s(e2) ? "dny" : "dní") : d2 + "dny";
          case "M":
            return n2 || r2 ? "měsíc" : "měsícem";
          case "MM":
            return n2 || r2 ? d2 + (s(e2) ? "měsíce" : "měsíců") : d2 + "měsíci";
          case "y":
            return n2 || r2 ? "rok" : "rokem";
          case "yy":
            return n2 || r2 ? d2 + (s(e2) ? "roky" : "let") : d2 + "lety";
        }
      }
      var d = {
        name: "cs",
        weekdays: "neděle_pondělí_úterý_středa_čtvrtek_pátek_sobota".split("_"),
        weekdaysShort: "ne_po_út_st_čt_pá_so".split("_"),
        weekdaysMin: "ne_po_út_st_čt_pá_so".split("_"),
        months: "leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec".split("_"),
        monthsShort: "led_úno_bře_dub_kvě_čvn_čvc_srp_zář_říj_lis_pro".split("_"),
        weekStart: 1,
        yearStart: 4,
        ordinal: function ordinal(e2) {
          return e2 + ".";
        },
        formats: {
          LT: "H:mm",
          LTS: "H:mm:ss",
          L: "DD.MM.YYYY",
          LL: "D. MMMM YYYY",
          LLL: "D. MMMM YYYY H:mm",
          LLLL: "dddd D. MMMM YYYY H:mm",
          l: "D. M. YYYY"
        },
        relativeTime: {
          future: "za %s",
          past: "před %s",
          s: r,
          m: r,
          mm: r,
          h: r,
          hh: r,
          d: r,
          dd: r,
          M: r,
          MM: r,
          y: r,
          yy: r
        }
      };
      return t.default.locale(d, null, true), d;
    });
  })(cs);
  var da = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, t) {
      module.exports = t(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function t(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var d = t(e),
        n = {
          name: "da",
          weekdays: "søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag".split("_"),
          weekdaysShort: "søn._man._tirs._ons._tors._fre._lør.".split("_"),
          weekdaysMin: "sø._ma._ti._on._to._fr._lø.".split("_"),
          months: "januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december".split("_"),
          monthsShort: "jan._feb._mar._apr._maj_juni_juli_aug._sept._okt._nov._dec.".split("_"),
          weekStart: 1,
          ordinal: function ordinal(e2) {
            return e2 + ".";
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD.MM.YYYY",
            LL: "D. MMMM YYYY",
            LLL: "D. MMMM YYYY HH:mm",
            LLLL: "dddd [d.] D. MMMM YYYY [kl.] HH:mm"
          },
          relativeTime: {
            future: "om %s",
            past: "%s siden",
            s: "få sekunder",
            m: "et minut",
            mm: "%d minutter",
            h: "en time",
            hh: "%d timer",
            d: "en dag",
            dd: "%d dage",
            M: "en måned",
            MM: "%d måneder",
            y: "et år",
            yy: "%d år"
          }
        };
      return d.default.locale(n, null, true), n;
    });
  })(da);
  var de = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function n(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = n(e),
        a = {
          s: "ein paar Sekunden",
          m: ["eine Minute", "einer Minute"],
          mm: "%d Minuten",
          h: ["eine Stunde", "einer Stunde"],
          hh: "%d Stunden",
          d: ["ein Tag", "einem Tag"],
          dd: ["%d Tage", "%d Tagen"],
          M: ["ein Monat", "einem Monat"],
          MM: ["%d Monate", "%d Monaten"],
          y: ["ein Jahr", "einem Jahr"],
          yy: ["%d Jahre", "%d Jahren"]
        };
      function i(e2, n2, t2) {
        var i2 = a[t2];
        return Array.isArray(i2) && (i2 = i2[n2 ? 0 : 1]), i2.replace("%d", e2);
      }
      var r = {
        name: "de",
        weekdays: "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split("_"),
        weekdaysShort: "So._Mo._Di._Mi._Do._Fr._Sa.".split("_"),
        weekdaysMin: "So_Mo_Di_Mi_Do_Fr_Sa".split("_"),
        months: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split("_"),
        monthsShort: "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sept._Okt._Nov._Dez.".split("_"),
        ordinal: function ordinal(e2) {
          return e2 + ".";
        },
        weekStart: 1,
        yearStart: 4,
        formats: {
          LTS: "HH:mm:ss",
          LT: "HH:mm",
          L: "DD.MM.YYYY",
          LL: "D. MMMM YYYY",
          LLL: "D. MMMM YYYY HH:mm",
          LLLL: "dddd, D. MMMM YYYY HH:mm"
        },
        relativeTime: {
          future: "in %s",
          past: "vor %s",
          s: i,
          m: i,
          mm: i,
          h: i,
          hh: i,
          d: i,
          dd: i,
          M: i,
          MM: i,
          y: i,
          yy: i
        }
      };
      return t.default.locale(r, null, true), r;
    });
  })(de);
  var el = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, _) {
      module.exports = _(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function _(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = _(e),
        d = {
          name: "el",
          weekdays: "Κυριακή_Δευτέρα_Τρίτη_Τετάρτη_Πέμπτη_Παρασκευή_Σάββατο".split("_"),
          weekdaysShort: "Κυρ_Δευ_Τρι_Τετ_Πεμ_Παρ_Σαβ".split("_"),
          weekdaysMin: "Κυ_Δε_Τρ_Τε_Πε_Πα_Σα".split("_"),
          months: "Ιανουάριος_Φεβρουάριος_Μάρτιος_Απρίλιος_Μάιος_Ιούνιος_Ιούλιος_Αύγουστος_Σεπτέμβριος_Οκτώβριος_Νοέμβριος_Δεκέμβριος".split("_"),
          monthsShort: "Ιαν_Φεβ_Μαρ_Απρ_Μαι_Ιουν_Ιουλ_Αυγ_Σεπτ_Οκτ_Νοε_Δεκ".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          weekStart: 1,
          relativeTime: {
            future: "σε %s",
            past: "πριν %s",
            s: "μερικά δευτερόλεπτα",
            m: "ένα λεπτό",
            mm: "%d λεπτά",
            h: "μία ώρα",
            hh: "%d ώρες",
            d: "μία μέρα",
            dd: "%d μέρες",
            M: "ένα μήνα",
            MM: "%d μήνες",
            y: "ένα χρόνο",
            yy: "%d χρόνια"
          },
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY h:mm A",
            LLLL: "dddd, D MMMM YYYY h:mm A"
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(el);
  var en = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n();
    }(commonjsGlobal, function () {
      return {
        name: "en",
        weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
        months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
        ordinal: function ordinal(e) {
          var n = ["th", "st", "nd", "rd"],
            t = e % 100;
          return "[" + e + (n[(t - 20) % 10] || n[t] || n[0]) + "]";
        }
      };
    });
  })(en);
  var enAu = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = a(e),
        _ = {
          name: "en-au",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          weekStart: 1,
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY h:mm A",
            LLLL: "dddd, D MMMM YYYY h:mm A"
          },
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          }
        };
      return t.default.locale(_, null, true), _;
    });
  })(enAu);
  var enCa = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var _ = a(e),
        t = {
          name: "en-ca",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "YYYY-MM-DD",
            LL: "MMMM D, YYYY",
            LLL: "MMMM D, YYYY h:mm A",
            LLLL: "dddd, MMMM D, YYYY h:mm A"
          },
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          }
        };
      return _.default.locale(t, null, true), t;
    });
  })(enCa);
  var enGb = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = a(e),
        _ = {
          name: "en-gb",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekStart: 1,
          yearStart: 4,
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm"
          },
          ordinal: function ordinal(e2) {
            var a2 = ["th", "st", "nd", "rd"],
              t2 = e2 % 100;
            return "[" + e2 + (a2[(t2 - 20) % 10] || a2[t2] || a2[0]) + "]";
          }
        };
      return t.default.locale(_, null, true), _;
    });
  })(enGb);
  var enIe = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = a(e),
        _ = {
          name: "en-ie",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          weekStart: 1,
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          }
        };
      return t.default.locale(_, null, true), _;
    });
  })(enIe);
  var enIl = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var _ = a(e),
        t = {
          name: "en-il",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          }
        };
      return _.default.locale(t, null, true), t;
    });
  })(enIl);
  var enIn = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = a(e),
        n = {
          name: "en-in",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekStart: 1,
          yearStart: 4,
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm"
          },
          ordinal: function ordinal(e2) {
            var a2 = ["th", "st", "nd", "rd"],
              t2 = e2 % 100;
            return "[" + e2 + (a2[(t2 - 20) % 10] || a2[t2] || a2[0]) + "]";
          }
        };
      return t.default.locale(n, null, true), n;
    });
  })(enIn);
  var enNz = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = a(e),
        n = {
          name: "en-nz",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          weekStart: 1,
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            var a2 = ["th", "st", "nd", "rd"],
              t2 = e2 % 100;
            return "[" + e2 + (a2[(t2 - 20) % 10] || a2[t2] || a2[0]) + "]";
          },
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY h:mm A",
            LLLL: "dddd, D MMMM YYYY h:mm A"
          },
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          }
        };
      return t.default.locale(n, null, true), n;
    });
  })(enNz);
  var enSg = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = a(e),
        _ = {
          name: "en-sg",
          weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
          months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
          weekStart: 1,
          weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
          monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
          weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "a few seconds",
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
          }
        };
      return t.default.locale(_, null, true), _;
    });
  })(enSg);
  var es = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, o) {
      module.exports = o(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function o(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var s = o(e),
        d = {
          name: "es",
          monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
          weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
          weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
          weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
          months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"),
          weekStart: 1,
          formats: {
            LT: "H:mm",
            LTS: "H:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY H:mm",
            LLLL: "dddd, D [de] MMMM [de] YYYY H:mm"
          },
          relativeTime: {
            future: "en %s",
            past: "hace %s",
            s: "unos segundos",
            m: "un minuto",
            mm: "%d minutos",
            h: "una hora",
            hh: "%d horas",
            d: "un día",
            dd: "%d días",
            M: "un mes",
            MM: "%d meses",
            y: "un año",
            yy: "%d años"
          },
          ordinal: function ordinal(e2) {
            return e2 + "º";
          }
        };
      return s.default.locale(d, null, true), d;
    });
  })(es);
  var esDo = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, o) {
      module.exports = o(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function o(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var s = o(e),
        d = {
          name: "es-do",
          weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
          weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
          weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
          months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"),
          monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
          weekStart: 1,
          relativeTime: {
            future: "en %s",
            past: "hace %s",
            s: "unos segundos",
            m: "un minuto",
            mm: "%d minutos",
            h: "una hora",
            hh: "%d horas",
            d: "un día",
            dd: "%d días",
            M: "un mes",
            MM: "%d meses",
            y: "un año",
            yy: "%d años"
          },
          ordinal: function ordinal(e2) {
            return e2 + "º";
          },
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "DD/MM/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY h:mm A",
            LLLL: "dddd, D [de] MMMM [de] YYYY h:mm A"
          }
        };
      return s.default.locale(d, null, true), d;
    });
  })(esDo);
  var esMx = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, o) {
      module.exports = o(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function o(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var s = o(e),
        d = {
          name: "es-mx",
          weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
          weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
          weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
          months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"),
          monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
          relativeTime: {
            future: "en %s",
            past: "hace %s",
            s: "unos segundos",
            m: "un minuto",
            mm: "%d minutos",
            h: "una hora",
            hh: "%d horas",
            d: "un día",
            dd: "%d días",
            M: "un mes",
            MM: "%d meses",
            y: "un año",
            yy: "%d años"
          },
          ordinal: function ordinal(e2) {
            return e2 + "º";
          },
          formats: {
            LT: "H:mm",
            LTS: "H:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY H:mm",
            LLLL: "dddd, D [de] MMMM [de] YYYY H:mm"
          }
        };
      return s.default.locale(d, null, true), d;
    });
  })(esMx);
  var esPr = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, o) {
      module.exports = o(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function o(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var s = o(e),
        d = {
          name: "es-pr",
          monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
          weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
          weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
          weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
          months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"),
          weekStart: 1,
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "MM/DD/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY h:mm A",
            LLLL: "dddd, D [de] MMMM [de] YYYY h:mm A"
          },
          relativeTime: {
            future: "en %s",
            past: "hace %s",
            s: "unos segundos",
            m: "un minuto",
            mm: "%d minutos",
            h: "una hora",
            hh: "%d horas",
            d: "un día",
            dd: "%d días",
            M: "un mes",
            MM: "%d meses",
            y: "un año",
            yy: "%d años"
          },
          ordinal: function ordinal(e2) {
            return e2 + "º";
          }
        };
      return s.default.locale(d, null, true), d;
    });
  })(esPr);
  var esUs = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, s) {
      module.exports = s(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function s(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var o = s(e),
        d = {
          name: "es-us",
          weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
          weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
          weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
          months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"),
          monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
          relativeTime: {
            future: "en %s",
            past: "hace %s",
            s: "unos segundos",
            m: "un minuto",
            mm: "%d minutos",
            h: "una hora",
            hh: "%d horas",
            d: "un día",
            dd: "%d días",
            M: "un mes",
            MM: "%d meses",
            y: "un año",
            yy: "%d años"
          },
          ordinal: function ordinal(e2) {
            return e2 + "º";
          },
          formats: {
            LT: "h:mm A",
            LTS: "h:mm:ss A",
            L: "MM/DD/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY h:mm A",
            LLLL: "dddd, D [de] MMMM [de] YYYY h:mm A"
          }
        };
      return o.default.locale(d, null, true), d;
    });
  })(esUs);
  var fo = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, t) {
      module.exports = t(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function t(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var a = t(e),
        r = {
          name: "fo",
          weekdays: "sunnudagur_mánadagur_týsdagur_mikudagur_hósdagur_fríggjadagur_leygardagur".split("_"),
          months: "januar_februar_mars_apríl_mai_juni_juli_august_september_oktober_november_desember".split("_"),
          weekStart: 1,
          weekdaysShort: "sun_mán_týs_mik_hós_frí_ley".split("_"),
          monthsShort: "jan_feb_mar_apr_mai_jun_jul_aug_sep_okt_nov_des".split("_"),
          weekdaysMin: "su_má_tý_mi_hó_fr_le".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D. MMMM, YYYY HH:mm"
          },
          relativeTime: {
            future: "um %s",
            past: "%s síðani",
            s: "fá sekund",
            m: "ein minuttur",
            mm: "%d minuttir",
            h: "ein tími",
            hh: "%d tímar",
            d: "ein dagur",
            dd: "%d dagar",
            M: "ein mánaður",
            MM: "%d mánaðir",
            y: "eitt ár",
            yy: "%d ár"
          }
        };
      return a.default.locale(r, null, true), r;
    });
  })(fo);
  var fr = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function n(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = n(e),
        i = {
          name: "fr",
          weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
          weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
          weekdaysMin: "di_lu_ma_me_je_ve_sa".split("_"),
          months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
          monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
          weekStart: 1,
          yearStart: 4,
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "dans %s",
            past: "il y a %s",
            s: "quelques secondes",
            m: "une minute",
            mm: "%d minutes",
            h: "une heure",
            hh: "%d heures",
            d: "un jour",
            dd: "%d jours",
            M: "un mois",
            MM: "%d mois",
            y: "un an",
            yy: "%d ans"
          },
          ordinal: function ordinal(e2) {
            return "" + e2 + (1 === e2 ? "er" : "");
          }
        };
      return t.default.locale(i, null, true), i;
    });
  })(fr);
  var frCa = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function n(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var i = n(e),
        _ = {
          name: "fr-ca",
          weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
          months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
          weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
          monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
          weekdaysMin: "di_lu_ma_me_je_ve_sa".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY-MM-DD",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "dans %s",
            past: "il y a %s",
            s: "quelques secondes",
            m: "une minute",
            mm: "%d minutes",
            h: "une heure",
            hh: "%d heures",
            d: "un jour",
            dd: "%d jours",
            M: "un mois",
            MM: "%d mois",
            y: "un an",
            yy: "%d ans"
          }
        };
      return i.default.locale(_, null, true), _;
    });
  })(frCa);
  var he = {
    exports: {}
  };
  (function (module, exports) {
    !function (Y, M) {
      module.exports = M(dayjs_minExports);
    }(commonjsGlobal, function (Y) {
      function M(Y2) {
        return Y2 && "object" == _typeof(Y2) && "default" in Y2 ? Y2 : {
          default: Y2
        };
      }
      var d = M(Y),
        e = {
          s: "מספר שניות",
          ss: "%d שניות",
          m: "דקה",
          mm: "%d דקות",
          h: "שעה",
          hh: "%d שעות",
          hh2: "שעתיים",
          d: "יום",
          dd: "%d ימים",
          dd2: "יומיים",
          M: "חודש",
          MM: "%d חודשים",
          MM2: "חודשיים",
          y: "שנה",
          yy: "%d שנים",
          yy2: "שנתיים"
        };
      function _(Y2, M2, d2) {
        return (e[d2 + (2 === Y2 ? "2" : "")] || e[d2]).replace("%d", Y2);
      }
      var l = {
        name: "he",
        weekdays: "ראשון_שני_שלישי_רביעי_חמישי_שישי_שבת".split("_"),
        weekdaysShort: "א׳_ב׳_ג׳_ד׳_ה׳_ו׳_ש׳".split("_"),
        weekdaysMin: "א׳_ב׳_ג׳_ד׳_ה׳_ו_ש׳".split("_"),
        months: "ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר".split("_"),
        monthsShort: "ינו_פבר_מרץ_אפר_מאי_יונ_יול_אוג_ספט_אוק_נוב_דצמ".split("_"),
        relativeTime: {
          future: "בעוד %s",
          past: "לפני %s",
          s: _,
          m: _,
          mm: _,
          h: _,
          hh: _,
          d: _,
          dd: _,
          M: _,
          MM: _,
          y: _,
          yy: _
        },
        ordinal: function ordinal(Y2) {
          return Y2;
        },
        format: {
          LT: "HH:mm",
          LTS: "HH:mm:ss",
          L: "DD/MM/YYYY",
          LL: "D [ב]MMMM YYYY",
          LLL: "D [ב]MMMM YYYY HH:mm",
          LLLL: "dddd, D [ב]MMMM YYYY HH:mm",
          l: "D/M/YYYY",
          ll: "D MMM YYYY",
          lll: "D MMM YYYY HH:mm",
          llll: "ddd, D MMM YYYY HH:mm"
        },
        formats: {
          LT: "HH:mm",
          LTS: "HH:mm:ss",
          L: "DD/MM/YYYY",
          LL: "D [ב]MMMM YYYY",
          LLL: "D [ב]MMMM YYYY HH:mm",
          LLLL: "dddd, D [ב]MMMM YYYY HH:mm",
          l: "D/M/YYYY",
          ll: "D MMM YYYY",
          lll: "D MMM YYYY HH:mm",
          llll: "ddd, D MMM YYYY HH:mm"
        }
      };
      return d.default.locale(l, null, true), l;
    });
  })(he);
  var hi = {
    exports: {}
  };
  (function (module, exports) {
    !function (_, e) {
      module.exports = e(dayjs_minExports);
    }(commonjsGlobal, function (_) {
      function e(_2) {
        return _2 && "object" == _typeof(_2) && "default" in _2 ? _2 : {
          default: _2
        };
      }
      var t = e(_),
        d = {
          name: "hi",
          weekdays: "रविवार_सोमवार_मंगलवार_बुधवार_गुरूवार_शुक्रवार_शनिवार".split("_"),
          months: "जनवरी_फ़रवरी_मार्च_अप्रैल_मई_जून_जुलाई_अगस्त_सितम्बर_अक्टूबर_नवम्बर_दिसम्बर".split("_"),
          weekdaysShort: "रवि_सोम_मंगल_बुध_गुरू_शुक्र_शनि".split("_"),
          monthsShort: "जन._फ़र._मार्च_अप्रै._मई_जून_जुल._अग._सित._अक्टू._नव._दिस.".split("_"),
          weekdaysMin: "र_सो_मं_बु_गु_शु_श".split("_"),
          ordinal: function ordinal(_2) {
            return _2;
          },
          formats: {
            LT: "A h:mm बजे",
            LTS: "A h:mm:ss बजे",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY, A h:mm बजे",
            LLLL: "dddd, D MMMM YYYY, A h:mm बजे"
          },
          relativeTime: {
            future: "%s में",
            past: "%s पहले",
            s: "कुछ ही क्षण",
            m: "एक मिनट",
            mm: "%d मिनट",
            h: "एक घंटा",
            hh: "%d घंटे",
            d: "एक दिन",
            dd: "%d दिन",
            M: "एक महीने",
            MM: "%d महीने",
            y: "एक वर्ष",
            yy: "%d वर्ष"
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(hi);
  var hu = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function n(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = n(e),
        r = {
          name: "hu",
          weekdays: "vasárnap_hétfő_kedd_szerda_csütörtök_péntek_szombat".split("_"),
          weekdaysShort: "vas_hét_kedd_sze_csüt_pén_szo".split("_"),
          weekdaysMin: "v_h_k_sze_cs_p_szo".split("_"),
          months: "január_február_március_április_május_június_július_augusztus_szeptember_október_november_december".split("_"),
          monthsShort: "jan_feb_márc_ápr_máj_jún_júl_aug_szept_okt_nov_dec".split("_"),
          ordinal: function ordinal(e2) {
            return e2 + ".";
          },
          weekStart: 1,
          relativeTime: {
            future: "%s múlva",
            past: "%s",
            s: function s(e2, n2, t2, r2) {
              return "néhány másodperc" + (r2 || n2 ? "" : "e");
            },
            m: function m(e2, n2, t2, r2) {
              return "egy perc" + (r2 || n2 ? "" : "e");
            },
            mm: function mm(e2, n2, t2, r2) {
              return e2 + " perc" + (r2 || n2 ? "" : "e");
            },
            h: function h(e2, n2, t2, r2) {
              return "egy " + (r2 || n2 ? "óra" : "órája");
            },
            hh: function hh(e2, n2, t2, r2) {
              return e2 + " " + (r2 || n2 ? "óra" : "órája");
            },
            d: function d(e2, n2, t2, r2) {
              return "egy " + (r2 || n2 ? "nap" : "napja");
            },
            dd: function dd(e2, n2, t2, r2) {
              return e2 + " " + (r2 || n2 ? "nap" : "napja");
            },
            M: function M(e2, n2, t2, r2) {
              return "egy " + (r2 || n2 ? "hónap" : "hónapja");
            },
            MM: function MM(e2, n2, t2, r2) {
              return e2 + " " + (r2 || n2 ? "hónap" : "hónapja");
            },
            y: function y(e2, n2, t2, r2) {
              return "egy " + (r2 || n2 ? "év" : "éve");
            },
            yy: function yy(e2, n2, t2, r2) {
              return e2 + " " + (r2 || n2 ? "év" : "éve");
            }
          },
          formats: {
            LT: "H:mm",
            LTS: "H:mm:ss",
            L: "YYYY.MM.DD.",
            LL: "YYYY. MMMM D.",
            LLL: "YYYY. MMMM D. H:mm",
            LLLL: "YYYY. MMMM D., dddd H:mm"
          }
        };
      return t.default.locale(r, null, true), r;
    });
  })(hu);
  var it = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, o) {
      module.exports = o(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function o(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = o(e),
        n = {
          name: "it",
          weekdays: "domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato".split("_"),
          weekdaysShort: "dom_lun_mar_mer_gio_ven_sab".split("_"),
          weekdaysMin: "do_lu_ma_me_gi_ve_sa".split("_"),
          months: "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split("_"),
          weekStart: 1,
          monthsShort: "gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic".split("_"),
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "tra %s",
            past: "%s fa",
            s: "qualche secondo",
            m: "un minuto",
            mm: "%d minuti",
            h: "un' ora",
            hh: "%d ore",
            d: "un giorno",
            dd: "%d giorni",
            M: "un mese",
            MM: "%d mesi",
            y: "un anno",
            yy: "%d anni"
          },
          ordinal: function ordinal(e2) {
            return e2 + "º";
          }
        };
      return t.default.locale(n, null, true), n;
    });
  })(it);
  var ja = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, _) {
      module.exports = _(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function _(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = _(e),
        d = {
          name: "ja",
          weekdays: "日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日".split("_"),
          weekdaysShort: "日_月_火_水_木_金_土".split("_"),
          weekdaysMin: "日_月_火_水_木_金_土".split("_"),
          months: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
          monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
          ordinal: function ordinal(e2) {
            return e2 + "日";
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY/MM/DD",
            LL: "YYYY年M月D日",
            LLL: "YYYY年M月D日 HH:mm",
            LLLL: "YYYY年M月D日 dddd HH:mm",
            l: "YYYY/MM/DD",
            ll: "YYYY年M月D日",
            lll: "YYYY年M月D日 HH:mm",
            llll: "YYYY年M月D日(ddd) HH:mm"
          },
          meridiem: function meridiem(e2) {
            return e2 < 12 ? "午前" : "午後";
          },
          relativeTime: {
            future: "%s後",
            past: "%s前",
            s: "数秒",
            m: "1分",
            mm: "%d分",
            h: "1時間",
            hh: "%d時間",
            d: "1日",
            dd: "%d日",
            M: "1ヶ月",
            MM: "%dヶ月",
            y: "1年",
            yy: "%d年"
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(ja);
  var ko = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, _) {
      module.exports = _(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function _(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var d = _(e),
        t = {
          name: "ko",
          weekdays: "일요일_월요일_화요일_수요일_목요일_금요일_토요일".split("_"),
          weekdaysShort: "일_월_화_수_목_금_토".split("_"),
          weekdaysMin: "일_월_화_수_목_금_토".split("_"),
          months: "1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월".split("_"),
          monthsShort: "1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월".split("_"),
          ordinal: function ordinal(e2) {
            return e2 + "일";
          },
          formats: {
            LT: "A h:mm",
            LTS: "A h:mm:ss",
            L: "YYYY.MM.DD.",
            LL: "YYYY년 MMMM D일",
            LLL: "YYYY년 MMMM D일 A h:mm",
            LLLL: "YYYY년 MMMM D일 dddd A h:mm",
            l: "YYYY.MM.DD.",
            ll: "YYYY년 MMMM D일",
            lll: "YYYY년 MMMM D일 A h:mm",
            llll: "YYYY년 MMMM D일 dddd A h:mm"
          },
          meridiem: function meridiem(e2) {
            return e2 < 12 ? "오전" : "오후";
          },
          relativeTime: {
            future: "%s 후",
            past: "%s 전",
            s: "몇 초",
            m: "1분",
            mm: "%d분",
            h: "한 시간",
            hh: "%d시간",
            d: "하루",
            dd: "%d일",
            M: "한 달",
            MM: "%d달",
            y: "일 년",
            yy: "%d년"
          }
        };
      return d.default.locale(t, null, true), t;
    });
  })(ko);
  var nl = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var d = a(e),
        n = {
          name: "nl",
          weekdays: "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split("_"),
          weekdaysShort: "zo._ma._di._wo._do._vr._za.".split("_"),
          weekdaysMin: "zo_ma_di_wo_do_vr_za".split("_"),
          months: "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split("_"),
          monthsShort: "jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec".split("_"),
          ordinal: function ordinal(e2) {
            return "[" + e2 + (1 === e2 || 8 === e2 || e2 >= 20 ? "ste" : "de") + "]";
          },
          weekStart: 1,
          yearStart: 4,
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD-MM-YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "over %s",
            past: "%s geleden",
            s: "een paar seconden",
            m: "een minuut",
            mm: "%d minuten",
            h: "een uur",
            hh: "%d uur",
            d: "een dag",
            dd: "%d dagen",
            M: "een maand",
            MM: "%d maanden",
            y: "een jaar",
            yy: "%d jaar"
          }
        };
      return d.default.locale(n, null, true), n;
    });
  })(nl);
  var nlBe = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var n = a(e),
        d = {
          name: "nl-be",
          weekdays: "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split("_"),
          months: "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split("_"),
          monthsShort: "jan._feb._mrt._apr._mei_jun._jul._aug._sep._okt._nov._dec.".split("_"),
          weekStart: 1,
          weekdaysShort: "zo._ma._di._wo._do._vr._za.".split("_"),
          weekdaysMin: "zo_ma_di_wo_do_vr_za".split("_"),
          ordinal: function ordinal(e2) {
            return e2;
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd D MMMM YYYY HH:mm"
          },
          relativeTime: {
            future: "over %s",
            past: "%s geleden",
            s: "een paar seconden",
            m: "één minuut",
            mm: "%d minuten",
            h: "één uur",
            hh: "%d uur",
            d: "één dag",
            dd: "%d dagen",
            M: "één maand",
            MM: "%d maanden",
            y: "één jaar",
            yy: "%d jaar"
          }
        };
      return n.default.locale(d, null, true), d;
    });
  })(nlBe);
  var pt = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, a) {
      module.exports = a(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function a(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var o = a(e),
        t = {
          name: "pt",
          weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
          weekdaysShort: "dom_seg_ter_qua_qui_sex_sab".split("_"),
          weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sa".split("_"),
          months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
          monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
          ordinal: function ordinal(e2) {
            return e2 + "º";
          },
          weekStart: 1,
          yearStart: 4,
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
            LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
          },
          relativeTime: {
            future: "em %s",
            past: "há %s",
            s: "alguns segundos",
            m: "um minuto",
            mm: "%d minutos",
            h: "uma hora",
            hh: "%d horas",
            d: "um dia",
            dd: "%d dias",
            M: "um mês",
            MM: "%d meses",
            y: "um ano",
            yy: "%d anos"
          }
        };
      return o.default.locale(t, null, true), t;
    });
  })(pt);
  var ptBr = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, o) {
      module.exports = o(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function o(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var a = o(e),
        s = {
          name: "pt-br",
          weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
          weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
          weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
          months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
          monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
          ordinal: function ordinal(e2) {
            return e2 + "º";
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YYYY",
            LL: "D [de] MMMM [de] YYYY",
            LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
            LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
          },
          relativeTime: {
            future: "em %s",
            past: "há %s",
            s: "poucos segundos",
            m: "um minuto",
            mm: "%d minutos",
            h: "uma hora",
            hh: "%d horas",
            d: "um dia",
            dd: "%d dias",
            M: "um mês",
            MM: "%d meses",
            y: "um ano",
            yy: "%d anos"
          }
        };
      return a.default.locale(s, null, true), s;
    });
  })(ptBr);
  var sv = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, t) {
      module.exports = t(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function t(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var a = t(e),
        d = {
          name: "sv",
          weekdays: "söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag".split("_"),
          weekdaysShort: "sön_mån_tis_ons_tor_fre_lör".split("_"),
          weekdaysMin: "sö_må_ti_on_to_fr_lö".split("_"),
          months: "januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december".split("_"),
          monthsShort: "jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec".split("_"),
          weekStart: 1,
          yearStart: 4,
          ordinal: function ordinal(e2) {
            var t2 = e2 % 10;
            return "[" + e2 + (1 === t2 || 2 === t2 ? "a" : "e") + "]";
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY-MM-DD",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY [kl.] HH:mm",
            LLLL: "dddd D MMMM YYYY [kl.] HH:mm",
            lll: "D MMM YYYY HH:mm",
            llll: "ddd D MMM YYYY HH:mm"
          },
          relativeTime: {
            future: "om %s",
            past: "för %s sedan",
            s: "några sekunder",
            m: "en minut",
            mm: "%d minuter",
            h: "en timme",
            hh: "%d timmar",
            d: "en dag",
            dd: "%d dagar",
            M: "en månad",
            MM: "%d månader",
            y: "ett år",
            yy: "%d år"
          }
        };
      return a.default.locale(d, null, true), d;
    });
  })(sv);
  var zh = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, _) {
      module.exports = _(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function _(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = _(e),
        d = {
          name: "zh",
          weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split("_"),
          weekdaysShort: "周日_周一_周二_周三_周四_周五_周六".split("_"),
          weekdaysMin: "日_一_二_三_四_五_六".split("_"),
          months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split("_"),
          monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
          ordinal: function ordinal(e2, _2) {
            return "W" === _2 ? e2 + "周" : e2 + "日";
          },
          weekStart: 1,
          yearStart: 4,
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY/MM/DD",
            LL: "YYYY年M月D日",
            LLL: "YYYY年M月D日Ah点mm分",
            LLLL: "YYYY年M月D日ddddAh点mm分",
            l: "YYYY/M/D",
            ll: "YYYY年M月D日",
            lll: "YYYY年M月D日 HH:mm",
            llll: "YYYY年M月D日dddd HH:mm"
          },
          relativeTime: {
            future: "%s后",
            past: "%s前",
            s: "几秒",
            m: "1 分钟",
            mm: "%d 分钟",
            h: "1 小时",
            hh: "%d 小时",
            d: "1 天",
            dd: "%d 天",
            M: "1 个月",
            MM: "%d 个月",
            y: "1 年",
            yy: "%d 年"
          },
          meridiem: function meridiem(e2, _2) {
            var t2 = 100 * e2 + _2;
            return t2 < 600 ? "凌晨" : t2 < 900 ? "早上" : t2 < 1100 ? "上午" : t2 < 1300 ? "中午" : t2 < 1800 ? "下午" : "晚上";
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(zh);
  var zhCn = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, _) {
      module.exports = _(dayjs_minExports);
    }(commonjsGlobal, function (e) {
      function _(e2) {
        return e2 && "object" == _typeof(e2) && "default" in e2 ? e2 : {
          default: e2
        };
      }
      var t = _(e),
        d = {
          name: "zh-cn",
          weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split("_"),
          weekdaysShort: "周日_周一_周二_周三_周四_周五_周六".split("_"),
          weekdaysMin: "日_一_二_三_四_五_六".split("_"),
          months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split("_"),
          monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
          ordinal: function ordinal(e2, _2) {
            return "W" === _2 ? e2 + "周" : e2 + "日";
          },
          weekStart: 1,
          yearStart: 4,
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY/MM/DD",
            LL: "YYYY年M月D日",
            LLL: "YYYY年M月D日Ah点mm分",
            LLLL: "YYYY年M月D日ddddAh点mm分",
            l: "YYYY/M/D",
            ll: "YYYY年M月D日",
            lll: "YYYY年M月D日 HH:mm",
            llll: "YYYY年M月D日dddd HH:mm"
          },
          relativeTime: {
            future: "%s内",
            past: "%s前",
            s: "几秒",
            m: "1 分钟",
            mm: "%d 分钟",
            h: "1 小时",
            hh: "%d 小时",
            d: "1 天",
            dd: "%d 天",
            M: "1 个月",
            MM: "%d 个月",
            y: "1 年",
            yy: "%d 年"
          },
          meridiem: function meridiem(e2, _2) {
            var t2 = 100 * e2 + _2;
            return t2 < 600 ? "凌晨" : t2 < 900 ? "早上" : t2 < 1100 ? "上午" : t2 < 1300 ? "中午" : t2 < 1800 ? "下午" : "晚上";
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(zhCn);
  var zhHk = {
    exports: {}
  };
  (function (module, exports) {
    !function (_, e) {
      module.exports = e(dayjs_minExports);
    }(commonjsGlobal, function (_) {
      function e(_2) {
        return _2 && "object" == _typeof(_2) && "default" in _2 ? _2 : {
          default: _2
        };
      }
      var t = e(_),
        d = {
          name: "zh-hk",
          months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split("_"),
          monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
          weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split("_"),
          weekdaysShort: "週日_週一_週二_週三_週四_週五_週六".split("_"),
          weekdaysMin: "日_一_二_三_四_五_六".split("_"),
          ordinal: function ordinal(_2, e2) {
            return "W" === e2 ? _2 + "週" : _2 + "日";
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY/MM/DD",
            LL: "YYYY年M月D日",
            LLL: "YYYY年M月D日 HH:mm",
            LLLL: "YYYY年M月D日dddd HH:mm"
          },
          relativeTime: {
            future: "%s內",
            past: "%s前",
            s: "幾秒",
            m: "一分鐘",
            mm: "%d 分鐘",
            h: "一小時",
            hh: "%d 小時",
            d: "一天",
            dd: "%d 天",
            M: "一個月",
            MM: "%d 個月",
            y: "一年",
            yy: "%d 年"
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(zhHk);
  var zhTw = {
    exports: {}
  };
  (function (module, exports) {
    !function (_, e) {
      module.exports = e(dayjs_minExports);
    }(commonjsGlobal, function (_) {
      function e(_2) {
        return _2 && "object" == _typeof(_2) && "default" in _2 ? _2 : {
          default: _2
        };
      }
      var t = e(_),
        d = {
          name: "zh-tw",
          weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split("_"),
          weekdaysShort: "週日_週一_週二_週三_週四_週五_週六".split("_"),
          weekdaysMin: "日_一_二_三_四_五_六".split("_"),
          months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split("_"),
          monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
          ordinal: function ordinal(_2, e2) {
            return "W" === e2 ? _2 + "週" : _2 + "日";
          },
          formats: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "YYYY/MM/DD",
            LL: "YYYY年M月D日",
            LLL: "YYYY年M月D日 HH:mm",
            LLLL: "YYYY年M月D日dddd HH:mm",
            l: "YYYY/M/D",
            ll: "YYYY年M月D日",
            lll: "YYYY年M月D日 HH:mm",
            llll: "YYYY年M月D日dddd HH:mm"
          },
          relativeTime: {
            future: "%s內",
            past: "%s前",
            s: "幾秒",
            m: "1 分鐘",
            mm: "%d 分鐘",
            h: "1 小時",
            hh: "%d 小時",
            d: "1 天",
            dd: "%d 天",
            M: "1 個月",
            MM: "%d 個月",
            y: "1 年",
            yy: "%d 年"
          },
          meridiem: function meridiem(_2, e2) {
            var t2 = 100 * _2 + e2;
            return t2 < 600 ? "凌晨" : t2 < 900 ? "早上" : t2 < 1100 ? "上午" : t2 < 1300 ? "中午" : t2 < 1800 ? "下午" : "晚上";
          }
        };
      return t.default.locale(d, null, true), d;
    });
  })(zhTw);
  var advancedFormat$1 = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, t) {
      module.exports = t();
    }(commonjsGlobal, function () {
      return function (e, t) {
        var r = t.prototype,
          n = r.format;
        r.format = function (e2) {
          var t2 = this,
            r2 = this.$locale();
          if (!this.isValid()) return n.bind(this)(e2);
          var s = this.$utils(),
            a = (e2 || "YYYY-MM-DDTHH:mm:ssZ").replace(/\[([^\]]+)]|Q|wo|ww|w|WW|W|zzz|z|gggg|GGGG|Do|X|x|k{1,2}|S/g, function (e3) {
              switch (e3) {
                case "Q":
                  return Math.ceil((t2.$M + 1) / 3);
                case "Do":
                  return r2.ordinal(t2.$D);
                case "gggg":
                  return t2.weekYear();
                case "GGGG":
                  return t2.isoWeekYear();
                case "wo":
                  return r2.ordinal(t2.week(), "W");
                case "w":
                case "ww":
                  return s.s(t2.week(), "w" === e3 ? 1 : 2, "0");
                case "W":
                case "WW":
                  return s.s(t2.isoWeek(), "W" === e3 ? 1 : 2, "0");
                case "k":
                case "kk":
                  return s.s(String(0 === t2.$H ? 24 : t2.$H), "k" === e3 ? 1 : 2, "0");
                case "X":
                  return Math.floor(t2.$d.getTime() / 1e3);
                case "x":
                  return t2.$d.getTime();
                case "z":
                  return "[" + t2.offsetName() + "]";
                case "zzz":
                  return "[" + t2.offsetName("long") + "]";
                default:
                  return e3;
              }
            });
          return n.bind(this)(a);
        };
      };
    });
  })(advancedFormat$1);
  var advancedFormatExports = advancedFormat$1.exports;
  var advancedFormat = /* @__PURE__ */getDefaultExportFromCjs(advancedFormatExports);
  var localizedFormat$1 = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, t) {
      module.exports = t();
    }(commonjsGlobal, function () {
      var e = {
        LTS: "h:mm:ss A",
        LT: "h:mm A",
        L: "MM/DD/YYYY",
        LL: "MMMM D, YYYY",
        LLL: "MMMM D, YYYY h:mm A",
        LLLL: "dddd, MMMM D, YYYY h:mm A"
      };
      return function (t, o, n) {
        var r = o.prototype,
          i = r.format;
        n.en.formats = e, r.format = function (t2) {
          void 0 === t2 && (t2 = "YYYY-MM-DDTHH:mm:ssZ");
          var o2 = this.$locale().formats,
            n2 = function (t3, o3) {
              return t3.replace(/(\[[^\]]+])|(LTS?|l{1,4}|L{1,4})/g, function (t4, n3, r2) {
                var i2 = r2 && r2.toUpperCase();
                return n3 || o3[r2] || e[r2] || o3[i2].replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, function (e2, t5, o4) {
                  return t5 || o4.slice(1);
                });
              });
            }(t2, void 0 === o2 ? {} : o2);
          return i.call(this, n2);
        };
      };
    });
  })(localizedFormat$1);
  var localizedFormatExports = localizedFormat$1.exports;
  var localizedFormat = /* @__PURE__ */getDefaultExportFromCjs(localizedFormatExports);
  var updateLocale$1 = {
    exports: {}
  };
  (function (module, exports) {
    !function (e, n) {
      module.exports = n();
    }(commonjsGlobal, function () {
      return function (e, n, t) {
        t.updateLocale = function (e2, n2) {
          var o = t.Ls[e2];
          if (o) return (n2 ? Object.keys(n2) : []).forEach(function (e3) {
            o[e3] = n2[e3];
          }), o;
        };
      };
    });
  })(updateLocale$1);
  var updateLocaleExports = updateLocale$1.exports;
  var updateLocale = /* @__PURE__ */getDefaultExportFromCjs(updateLocaleExports);
  function useDateLocalizer(date, locale, pattern, length, includeTime) {
    dayjs.extend(advancedFormat);
    dayjs.extend(localizedFormat);
    dayjs.extend(updateLocale);
    var formattedDate;
    var datePatternPreferenceToFormat;
    var patternAbstraction;
    if (locale.startsWith("es") || locale.startsWith("pt")) {
      formattedDate = accountForDE(date, locale, pattern, length, includeTime);
    } else {
      pattern.includes("US") ? patternAbstraction = "US" : patternAbstraction = "UK";
      datePatternPreferenceToFormat = {
        US: {
          full: "ddd MMMM D, YYYY",
          // 'Fri, August 4, 2023'
          long: "MMMM D, YYYY",
          // 'August 4, 2023'
          medium: "MMM YYYY",
          // 'Aug 2023'
          short: "MM/DD/YYYY"
          // '08/04/2023'
        },
        UK: {
          full: "ddd, D MMMM YYYY",
          // 'Fri, 4 August 2023'
          long: "D MMMM YYYY",
          // '4 August 2023' 
          medium: "MMM YYYY",
          // 'Aug 2023'
          short: "DD/MM/YYYY"
          // '04/08/2023'
        }
      };
      formattedDate = dayjs(date).locale(locale).format(datePatternPreferenceToFormat[patternAbstraction][length]);
      if (includeTime === true && (length === "full" || length === "short")) {
        if (pattern.includes("24")) {
          formattedDate = dayjs(date).locale(locale).format(datePatternPreferenceToFormat[patternAbstraction][length] += " H:mm");
        } else {
          formattedDate = dayjs(date).locale(locale).format(datePatternPreferenceToFormat[patternAbstraction][length] += " h:mm a");
        }
      }
    }
    return formattedDate;
  }
  function accountForDE(date, locale, pattern, length, includeTime) {
    var formattedDate;
    var formatsWithDe = {
      full: "ddd D [de] MMMM [de] YYYY",
      // vie. 4 de agosto de 2023
      long: "D [de] MMMM [de] YYYY",
      // 4 de agosto de 2023
      medium: "MMM YYYY",
      // 'ago 2023'
      short: "DD/MM/YYYY"
      // '04/08/2023'
    };
    formattedDate = dayjs(date).locale(locale).format(formatsWithDe[length]);
    if (includeTime === true && (length === "full" || length === "short")) {
      if (pattern.includes("24")) {
        formattedDate = dayjs(date).locale(locale).format(formatsWithDe[length] += " H:mm");
      } else {
        formattedDate = dayjs(date).locale(locale).format(formatsWithDe[length] += " h:mm a");
      }
    }
    return formattedDate;
  }
});
