define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/page/request", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/pages-signup-forms/SignupForm"], function (declare, _WidgetBase, _TemplatedMixin, request, domConstruct, lang, SignupForm) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<div></div>",
    endpoint: "",
    settings: null,
    postCreate: function () {
      this.inherited(arguments);
      if (this.settings) {
        this.renderSignupForm(this.settings);
        return;
      }
      request.get(this.endpoint).then(lang.hitch(this, function (settings) {
        this.renderSignupForm(settings);
      }));
    },
    renderSignupForm: function (settings) {
      var f = new SignupForm({
        config: settings.config,
        trackSubmitUrl: settings.trackSubmitUrl,
        subscribeUrl: settings.subscribeUrl,
        appDomain: settings.appDomain,
        honeypotFieldName: settings.honeypotFieldName,
        honeytime: settings.honeytime,
        recaptchaSitekey: settings.recaptchaSitekey,
        captchaEnabled: settings.captchaEnabled,
        isPreview: settings.isPreview,
        translations: settings.language
      });
      f.placeAt(this.domNode);
      f.startup();
    }
  });
});