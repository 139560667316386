/**
 * @module mojo/utils/I18nTranslation
 *
 * Translation utility methods
 */
define([// Base helpers
"dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/aba3733e3c8f70b37f672f878a7280e3f754dbd1/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// MC-specific utilities
"mojo/url", "mojo/page/data", "mojo/widgets/internationalization/logger", "mojo/user", "mojo/i18n-formatting",
// Flag Helpers
"mojo/lib/flags", "mojo/context"], function (dojo, Deferred, mUrl, pageData, logger, user, i18nFormatting, flags, context) {
  // Storage space for translations that provides a small level of persistence.
  // content
  var object = null;

  // There's a potential of us to have a blob of translations in the dom. If available,
  // we should leverage those.
  var IN_PAGE_TRANSLATION_TARGET_ID = "js-dojo-translation-data";
  var defaultLocale = "en";

  // error boundary for i18n-formatting library based utilities
  function formattingLibErrorBoundary(func, message) {
    var response = ""; // default response value to empty string to avoid visual bug
    try {
      response = func(); // reassign to function if catch is successful
    } catch (error) {
      console.warn("formatting library error:", message);
      if (window.bugsnagClient) {
        window.bugsnagClient.notify(error);
      }
    } finally {
      return response;
    }
  }
  var translation = {
    // Somewhat leaky heuristic for whether we should attempt to try an in-page data source
    isInPageTranslationsAvailable: function () {
      return pageData.isAvailable(IN_PAGE_TRANSLATION_TARGET_ID);
    },
    /**
     * Get the dojo translation object from the server
     *
     * @param {errCallback} errCallback - errCallback
     *
     * @returns {json} - Json Response
     */
    getTranslationFileObject: function (errCallback) {
      return dojo.xhrGet({
        "url": mUrl.toUrl("/i18n/language/get-translation-data"),
        handleAs: "json",
        error: errCallback
      });
    },
    /**
     * Retrieve the variables within the curly brackets
     *
     * @param {string} string - Full String
     *
     * @returns {array} - Array Response
     */
    getDynamicVariables: function (string) {
      // Matches curly brackets
      var pattern = /[^{}]*(?=\})/igm;
      var matches = string.match(pattern);
      return matches === null ? [] : matches.filter(function (str) {
        return str !== "";
      });
    },
    /**
     * Replace the variables within the curly brackets with actual data
     *
     * @param {string} string - Full string
     * @param {array} replaceWith - Replace with array
     *
     * @returns {string} - String Response
     */
    getTranslationString: function (string, replaceWith) {
      var replaceArray = translation.getDynamicVariables(string);
      for (var idx = 0; idx < replaceArray.length; idx++) {
        string = string.replace(new RegExp("{" + replaceArray[idx] + "}", "gi"), replaceWith[idx]);
      }
      return string;
    },
    /**
     * Retrieve the translation object from the ls
     *
     * @returns {json} - Json Response
     */
    getTranslationObject: function () {
      return object;
    },
    /**
     * Check if the object is empty or not
     *
     * @returns {boolean} - Boolean
     */
    isTranslationObjectEmpty: function () {
      return object === null ? true : false;
    },
    /**
     * Get formatted date-time based on locale
     * @param {string} date - Date to convert for date-time locale
     * @param {array} format - Full format options array
     * Format options available:
     *  weekday: 'narrow' | 'short' | 'long',
     *  era: 'narrow' | 'short' | 'long',
     *  year: 'numeric' | '2-digit',
     *  month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
     *  day: 'numeric' | '2-digit',
     *  hour: 'numeric' | '2-digit',
     *  minute: 'numeric' | '2-digit',
     *  second: 'numeric' | '2-digit',
     *  timeZoneName: 'short' | 'long',
     *  timeZone: 'Asia/Shanghai',
     *  hour12: true | false,
     * @returns {string} - Return string date formatted for locale
     */
    getLocaleDateTime: function (date, format) {
      var locale = user.displayLocale ? user.displayLocale : defaultLocale;

      // Use format if passed otherwise default to standard format
      // (Example: MM/DD/YYYY or DD/MM/YYYY)
      var dateFormat = format ? format : {
        month: "numeric",
        day: "numeric",
        year: "numeric"
      };
      return date.toLocaleString(locale, dateFormat);
    },
    /**
     * Replace the variables within the curly brackets with actual data
     *
     * @param {string} date - date to format
     * @param {string} length - desired length of formatted date
     * @param {boolean} includeTime - whether or not to include time in formatted date. defaults to false. time only included on short & full lengths
     *
     * @returns {string} - string type formatted date
    */
    getLocalizedDate: function (date, length, includeTime) {
      return formattingLibErrorBoundary(i18nFormatting.useDateLocalizer.bind(null, date, user.displayLocale, user.datePatternPreference, length, includeTime), "Failed trying to format date &/ time");
    },
    /**
     * Replace the variables within the curly brackets with actual data
     *
     * @param {string} key - The Key
     * @param {object} parameters - Parameters
     *
     * @returns {string} - String Response
     */
    translate: function (key, parameters) {
      parameters = typeof parameters !== "undefined" ? parameters : {};
      if (!object.hasOwnProperty(key)) {
        return "Invalid key: " + key;
      }
      if (parameters.hasOwnProperty("count")) {
        var multiple_key = key + "_" + parameters["count"];
        if (object.hasOwnProperty(multiple_key)) {
          key = multiple_key;
        }
      }
      var value = object[key];
      var replaceArray = translation.getDynamicVariables(value);
      for (var idx = 0; idx < replaceArray.length; idx++) {
        var replacementValue;
        if (parameters.hasOwnProperty("count")) {
          replacementValue = parameters[replaceArray[idx]];
        } else {
          replacementValue = parameters[idx];
        }
        value = value.replace(new RegExp("{" + replaceArray[idx] + "}", "gi"), replacementValue);
      }
      return value;
    },
    /**
     * Initialize translation
     *
     * @param {any} featureFlagName - featureFlagName
     *
     * @returns {Promise} Promise
     */
    initialize: function (featureFlagName) {
      var deferred = new Deferred();
      // Fetch the translation file only if the flag is turned on or if its not defined (Previously released features)
      if (!featureFlagName || featureFlagName && context.flagIsOn(flags[featureFlagName])) {
        // Attempt to pull from in-page translations if they're available.
        if (this.isInPageTranslationsAvailable()) {
          var inPageData = pageData.getAndParse(IN_PAGE_TRANSLATION_TARGET_ID);
          object = inPageData.translations;
          deferred.resolve(object);
          return deferred.promise;
        }

        // Fall back to a network request to gather translations
        var errCb = function () {
          deferred.resolve(null);
        };
        translation.getTranslationFileObject(errCb).then(function (data) {
          // the logs below ultimately get sent to ElasticSearch and include the user's user_id which
          // qualifies as sensitive data, and therefore requires us to classify these logs as sensitive.
          if (data.status === "success") {
            object = data.language;
            logger.log("Initialization successful", {
              flagName: featureFlagName,
              source: "network",
              library: "I18nTranslation",
              log_classification: "sensitive"
            });
            deferred.resolve(object);
          } else {
            logger.log("Initialization failure", {
              flagName: featureFlagName,
              source: "network",
              library: "I18nTranslation",
              details: "Return status not success",
              log_classification: "sensitive"
            });
            deferred.resolve(null);
          }
        });
      }
      return deferred.promise;
    },
    /**
     * Adds a locale property to the incoming JSON object if the locale query param exists.
     *
     * @param {object} params - The JSON object to be modified.
     * @returns {object} A modified JSON object with the added locale property if exists.
     */
    addLocaleParam: function (params) {
      var windowUrl = new URL(window.location.href);
      var locale = windowUrl.searchParams.get("locale");
      // Add the locale property to the object if locale is not empty
      if (locale && locale !== "") {
        params.locale = locale;
      }
      return params;
    }
  };
  return translation;
});